exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-como-fui-criada-tsx": () => import("./../../../src/pages/como-fui-criada.tsx" /* webpackChunkName: "component---src-pages-como-fui-criada-tsx" */),
  "component---src-pages-estarei-por-aqui-tsx": () => import("./../../../src/pages/estarei-por-aqui.tsx" /* webpackChunkName: "component---src-pages-estarei-por-aqui-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onde-me-encontrar-tsx": () => import("./../../../src/pages/onde-me-encontrar.tsx" /* webpackChunkName: "component---src-pages-onde-me-encontrar-tsx" */),
  "component---src-pages-quem-sou-eu-tsx": () => import("./../../../src/pages/quem-sou-eu.tsx" /* webpackChunkName: "component---src-pages-quem-sou-eu-tsx" */),
  "component---src-pages-sobre-a-ju-tsx": () => import("./../../../src/pages/sobre-a-ju.tsx" /* webpackChunkName: "component---src-pages-sobre-a-ju-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-pages-vamos-nos-conhecer-tsx": () => import("./../../../src/pages/vamos-nos-conhecer.tsx" /* webpackChunkName: "component---src-pages-vamos-nos-conhecer-tsx" */)
}

